import { DateTime } from "luxon";
import { Timezone, TimezoneDefinition } from "../types/holiday.types";

const timezoneMapping: Record<string, string> = {
  AKST: "America/Anchorage",
  EGST: "America/Scoresbysund",
  EGT: "America/Scoresbysund",
  PMST: "America/Miquelon",
  WGST: "America/Godthab",
  WGT: "America/Godthab",
  EST: "America/New_York",
  CST: "America/Chicago",
  MST: "America/Denver",
  PST: "America/Los_Angeles",
  HST: "Pacific/Honolulu",
  GMT: "Europe/London",
};

export const timezoneDisplay: Record<string, string> = {
  "America/Anchorage": "Alaska Time",
  "America/Scoresbysund": "East Greenland Time",
  "America/Miquelon": "Saint Pierre and Miquelon Time",
  "America/Godthab": "West Greenland Time",  
  "America/New_York": "Eastern Time",
  "America/Chicago": "Central Time",
  "America/Denver": "Mountain Time",
  "America/Los_Angeles": "Pacific Time",
  "Pacific/Honolulu": "Hawaii-Aleutian Time",
  "Europe/London": "British Time",
  "America/Halifax": "Atlantic Time",
  "America/St_Johns": "Newfoundland Time",
};

export const getIANATimezone = (tzName: string): string => {
  if (!tzName) {
    console.warn("No timezone provided, defaulting to UTC");
    return "UTC";
  }

  const cleanTz = tzName.trim().toUpperCase();
  const mappedTz = timezoneMapping[cleanTz];

  if (mappedTz) {
    return mappedTz;
  }

  console.warn(`Unrecognized timezone: ${tzName}, defaulting to UTC`);
  return "UTC";
};

export const findTimezoneByName = (
  tzTag: string,
  timezones: TimezoneDefinition[]
): TimezoneDefinition | undefined => {
  return timezones.find((tz) => tz.tag === tzTag);
};

export const getDisplayTimezones = (
  timezones: TimezoneDefinition[],
  userTimezone?: string
): TimezoneDefinition[] => {
  let tempTimezones = [...timezones].map((tz : any) => {return {...tz, displayName : timezoneDisplay[tz.name]}})
  if (!userTimezone) {
    return tempTimezones.sort((a, b) => {
      const nameCompare = a.displayName.localeCompare(b.displayName);
      return nameCompare !== 0 ? nameCompare : a.isDaylightSavings ? 1 : -1;
    });
  }

  return tempTimezones.sort((a, b) => {
    // if (a.tag === userTimezone) return -1;
    // if (b.tag === userTimezone) return 1;

    const nameCompare = a.displayName.localeCompare(b.displayName);
    return nameCompare !== 0 ? nameCompare : a.isDaylightSavings ? 1 : -1;
  });
};

export const getServerTimezone = (
  selectedTimezone: TimezoneDefinition,
  serverTimezones: Timezone[]
): Timezone | undefined => {
  if (!selectedTimezone) return undefined;

  const tagMapping: Record<string, string> = {
    HSDT: "HDT",
    HAST: "HST",
    NSDT: "NDT",
  };

  const serverTag = tagMapping[selectedTimezone.tag] || selectedTimezone.tag;
  return serverTimezones.find((serverTz) => serverTz.name === serverTag);
};

export const formatDateTime = (
  dt: string,
  userTimezone?: string,
  formatOverride?: string
): string => {
  if (!dt) {
    console.warn("No date provided to formatDateTime");
    return "";
  }

  try {
    const normalizedDate = dt.replace(" ", "T");

    const dateTime = DateTime.fromISO(normalizedDate, { zone: "UTC" });

    if (!dateTime.isValid) {
      console.error("Invalid datetime parsing:", {
        input: dt,
        normalizedDate,
        reason: dateTime.invalidReason,
        explanation: dateTime.invalidExplanation,
      });
      return dt;
    }

    if (!userTimezone) {
      console.warn("No user timezone provided, using UTC");
      return dateTime.toFormat(formatOverride ?? "MM/dd/yyyy hh:mm a 'UTC'");
    }

    const ianaTimezone = getIANATimezone(userTimezone);
    const localDateTime = dateTime.setZone(ianaTimezone);

    if (!localDateTime.isValid) {
      console.error("Invalid timezone conversion:", {
        originalDate: dt,
        timezone: userTimezone,
        ianaTimezone,
        reason: localDateTime.invalidReason,
      });
      return dateTime.toFormat(formatOverride ?? "MM/dd/yyyy hh:mm a 'UTC'");
    }

    const date = DateTime.fromISO(normalizedDate)
      .setZone(ianaTimezone)
      .setLocale(userTimezone === "GMT" ? "en-GB" : "en-US");

    return `${date.toFormat(formatOverride ?? "MM/dd/yyyy hh:mm a")} ${date.offsetNameShort}`;
  } catch (error) {
    console.error("Error in formatDateTime:", {
      input: dt,
      userTimezone,
      error,
    });
    return dt;
  }
};
